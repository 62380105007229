export const signUpClick = event => {
  event.preventDefault()

  var url = 'https://app.atlas.co/login'
  var utm_source = localStorage.getItem('source')
  var utm_medium = localStorage.getItem('medium')
  var utm_campaign = localStorage.getItem('campaign')
  var referrer = localStorage.getItem('referrer')
  var current_page = localStorage.getItem('current_page')

  var params = []

  if (utm_source) params.push('source=' + utm_source)
  if (utm_medium) params.push('medium=' + utm_medium)
  if (utm_campaign) params.push('campaign=' + utm_campaign)
  if (referrer) params.push('referrer=' + encodeURIComponent(referrer))
  if (current_page)
    params.push('website_origin=' + encodeURIComponent(current_page))

  if (params.length > 0) {
    url += '?' + params.join('&')
  }

  window.location.href = url
}
