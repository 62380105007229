import React from 'react'

import styles from './Footer.module.scss'

export default () => (
  <>
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerGrid}>
          <div>
            <img
              src="/images/logo.svg"
              style={{ width: '160px', height: '80px', marginBottom: '1rem' }}
              alt="Atlas Logo"
            />
            <p>
              We use privacy-friendly analytics from Plausible to collect
              anonymous usage data on our website and help center. Cookies are
              not used and no personal data is collected.
            </p>
          </div>
          {/* <div>
            <ul class={styles.listUnstyled}>
              <li>
                <h4>Product</h4>
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>
              <li>
                <a href="/community/maps">Community</a>
              </li>
              <li>
                <a href="/resources">Resources</a>
              </li>
            </ul>
          </div> */}
          <div>
            {/* <ul class={styles.listUnstyled}>
              <li>
                <h4>Product</h4>
              </li>
              <li>
                <a href="/#spatial-analysis">Spatial Analysis</a>
              </li>
              <li>
                <a href="/#data">Data</a>
              </li>
              <li>
                <a href="/#collaborate">Collaborate</a>
              </li>
            </ul> */}
            <ul class={styles.listUnstyled}>
              <li>
                <p>Resources</p>
              </li>
              {/* <li>
                <a href="/about">About</a>
              </li> */}
              {/* <li>
                <a href="/careers">Careers</a>
              </li> */}
              <li>
                <a href="/resources/">Blog</a>
              </li>
              <li>
                <a href="/community/maps/">Community Maps</a>
              </li>
              <li>
                <a href="/help/">Help Center</a>
              </li>
              <li>
                <a href="/help/docs/">Documentation</a>
              </li>
              <li>
                <a href="/help/guides/">Guides</a>
              </li>
              <li>
                <a href="/formats/">GIS Formats</a>
              </li>
              <li>
                <a href="/glossary/">Technical Glossary</a>
              </li>
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Company</p>
              </li>
              {/* <li>
                <a href="/about">About</a>
              </li> */}
              {/* <li>
                <a href="/careers">Careers</a>
              </li> */}
              <li>
                <a href="/pricing/">Pricing</a>
              </li>
              <li>
                <a href="/resources/">Resources</a>
              </li>
              <li>
                <p>Support</p>
              </li>
              <li>
                <a href="mailto:help@atlas.co">Talk to support</a>
              </li>
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <p>Trending posts</p>
              </li>
              <li>
                <a href="/blog/get-better-at-using-color-palettes-with-choropleth-maps/">
                  Get better at using color palettes with choropleth maps
                </a>
              </li>
              <li>
                <a href="/blog/free-data-sources-for-environmental-data/">
                  Free Data Sources for Environmental Data
                </a>
              </li>
              <li>
                <a href="/blog/new-in-atlas-flexible-popups/">
                  New in Atlas: Flexible Popups
                </a>
              </li>
              <li>
                <a href="/blog/reveal-patterns-with-heatmaps/">
                  Reveal Patterns with Heatmaps
                </a>
              </li>
              <li>
                <a href="/blog/travel-time-analysis-to-analyze-locations/">
                  Travel Time Analysis to Analyze Locations
                </a>
              </li>
              <li>
                <a href="/blog/bookmarks-shortcuts-to-interesting-places/">
                  Bookmarks - Shortcuts to Interesting Places
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class={styles.bottomFooter}>
        <div className={styles.bottomFooterContent}>
          <div>Copyright {new Date().getFullYear()} © Atlas.</div>
          <div className={styles.footerLinks}>
            <a href="/privacy-policy/">Privacy Policy</a>
            <a href="/cookie-policy/">Cookie Policy</a>
          </div>
        </div>
      </div>
    </div>
  </>
)
